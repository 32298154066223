<template>
  <v-card
    flat
    tile
    ripple
    :class="last ? '' : 'border-bottom'"
    @click="$emit('click')"
  >
    <v-card-text>
      <v-row no-gutters align="center">
        <v-col cols="3" sm="2" md="1" class="subtitle-2 text-truncate">
          <v-avatar
            size="32"
            :color="authorized ? 'green darken-1' : 'red darken-1'"
          >
            <v-icon dark>{{
              authorized ? 'mdi-account-check' : 'mdi-account-off'
            }}</v-icon>
          </v-avatar>
        </v-col>
        <v-col
          cols="3"
          sm="2"
          md="1"
          class="title text-truncate grey--text text--darken-3"
          >{{ client.table.number }}</v-col
        >

        <v-col sm="3" class="align-end subtitle-2 d-none d-sm-flex">
          <v-icon class="pr-2">mdi-clock-fast</v-icon>
          {{ createdTime }}
        </v-col>

        <v-col cols="6" sm="5" md="2" class="key red--text text--darken-2">{{
          client.key
        }}</v-col>

        <v-col class="align-end subtitle-2 text-truncate d-none d-md-flex">
          <span class="mr-5" v-if="authorizedTime">
            <v-icon class="pr-2">mdi-clock-check-outline</v-icon>
            {{ authorizedTime }}
          </span>
          <span v-else>{{ $t('client.notAuthorized') }}</span>
          <span v-if="client.authorized_by">
            <v-icon>mdi-account-circle</v-icon>
            {{ client.authorized_by.first_name }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    last: Boolean,
    client: Object,
  },

  computed: {
    authorized() {
      return (
        this.client.authorized_by != null && this.client.authorized_at != null
      );
    },

    createdTime() {
      return dayjs(this.client.created_at).format('HH:mm:ss');
    },

    authorizedTime() {
      if (this.client.authorized_at) {
        return dayjs(this.client.authorized_at).format('HH:mm:ss');
      }
      return '';
    },
  },

  methods: {},
};
</script>

<style></style>
