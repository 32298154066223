<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('clients.title') }}
          </h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <!-- Clients list cards -->
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <!-- Filtro por numero de mesa -->
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    v-model="filter.tables"
                    :items="tables"
                    label="Número de mesa"
                    item-value="uuid"
                    item-text="number"
                    prepend-icon="mdi-table-chair"
                    deletable-chips
                    multiple
                    chips
                    small-chips
                  ></v-select>
                </v-col>

                <!-- Filtro por estado de la autorizacion -->
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    v-model="filter.status"
                    :items="statusesList"
                    label="Estado autorización"
                    item-value="id"
                    item-text="value"
                    prepend-icon="mdi-chart-bubble"
                  ></v-select>
                </v-col>

                <!-- Buttons -->
                <v-col cols="12" class="text-right">
                  <v-btn @click="reset" class="ma-2">Limpiar filtros</v-btn>
                  <v-btn color="primary" @click="search(1)" class="ma-2"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>

              <!-- Clients table -->
              <v-card flat tile class="border-bottom">
                <v-card-text>
                  <v-row no-gutters class="grey--text text--darken-4">
                    <v-col
                      cols="3"
                      sm="2"
                      md="1"
                      class="subtitle-1 text-truncate"
                      >Auth</v-col
                    >
                    <v-col
                      cols="3"
                      sm="2"
                      md="1"
                      class="subtitle-1 text-truncate"
                      >Mesa</v-col
                    >
                    <v-col sm="3" class="subtitle-1 d-none d-sm-flex"
                      >Creación</v-col
                    >
                    <v-col cols="3" sm="5" md="2" class="subtitle-1">Key</v-col>
                    <v-col class="subtitle-1 text-truncate d-none d-md-flex"
                      >Detalles</v-col
                    >
                  </v-row>
                </v-card-text>
              </v-card>

              <v-alert
                :value="clients.length == 0"
                border="left"
                colored-border
                type="info"
                elevation="2"
                class="mt-4"
                >{{ $t('clients.noClients') }}</v-alert
              >

              <eximyr-client
                v-for="(client, index) in clients"
                :key="'client_' + client.id"
                :client="client"
                :last="clients.length - 1 == index"
                @click="showDialog(client)"
              ></eximyr-client>
              <!-- Clients table -->
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Clients list cards -->

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Edit dialog -->
    <eximyr-client-dialog
      v-if="client"
      :dialog="dialog"
      :client="client"
      :tables="tables"
      @close="closeDialog"
      @save="saveClient"
    ></eximyr-client-dialog>
    <!-- Edit dialog -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loadingClients || loadingTables"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';

import EximyrClient from './components/EximyrClient';
import EximyrClientDialog from './components/EximyrClientDialog';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EximyrClient,
    EximyrClientDialog,
  },

  data: () => ({
    page: null,

    filter: {
      tables: [],
      status: '',
    },

    tables: [],
    loadingTables: false,

    client: null,
    dialog: false,
  }),

  computed: {
    ...mapGetters('clients', {
      clients: 'clients',
      last_page: 'last_page',
      loadingClients: 'loading',
    }),

    statusesList() {
      return [
        {
          id: null,
          value: 'Cualquiera',
        },
        {
          id: 'authorized',
          value: 'Autorizado',
        },
        {
          id: 'unauthorized',
          value: 'No autorizado',
        },
      ];
    },
  },

  watch: {
    $route() {
      this.load();
    },
  },

  created() {
    // Set the current page if there is one set
    this.page = this.currentPage();

    // Save the loading state
    this.loadingTables = true;

    // Load all tables
    this.$store
      .dispatch('tables/load', this.$route.params.restaurant)
      .then(results => {
        this.tables = results.data;
        // Update the filter from the route
        this.filter.tables = this.currentFilterTables();
        this.filter.status = this.currentFilterStatus();

        this.load();
      })
      .catch(error => {
        this.storeError(error);
      })
      .finally(() => {
        this.loadingTables = false;
      });
  },

  methods: {
    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
        filter: this.filter,
        page: this.page,
      };

      this.$store
        .dispatch('clients/load', payload)
        .then(results => {
          // Clear all errors
          this.clearErrors();

          if (this.page > results.data.last_page) {
            // Reload
            this.search(results.data.last_page);
          }
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    search(page = null) {
      // Filter the orders
      this.$router
        .push({
          name: 'clients',
          params: {
            restaurant: this.$route.params.restaurant,
          },
          query: this.query(page),
        })
        // We need to ignore the error (same route)
        .catch(() => {});
    },

    reset() {
      this.filter.tables = [];
      this.filter.status = '';
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.tables.length > 0)
        query.tables = this.filter.tables.join(',');
      if (this.filter.status !== null) query.status = this.filter.status;

      return query;
    },

    showDialog(client) {
      this.client = client;
      this.dialog = true;
    },

    closeDialog() {
      this.client = null;
      this.dialog = false;
    },

    saveClient(event) {
      // Change the table or the authorization

      const payload = {
        restaurant: this.$route.params.restaurant,
        client: this.client.id,

        table_uuid: event.table,
        authorize: event.checkbox,
      };

      this.client = null;
      this.dialog = false;

      this.$store
        .dispatch('clients/update', payload)
        .then(() => {
          // Reload all data
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    /// FILTERS
    currentFilterTables() {
      let tables = this.$route.query.tables;
      if (!tables) {
        return [];
      }

      // Get all tables (tables=uuid,uuid,uuid)
      tables = tables.split(',');
      let filterTables = [];

      // Lets check the allowed values
      this.tables.forEach(t => {
        if (tables.indexOf(t.uuid) > -1) {
          filterTables.push(t.uuid);
        }
      });

      return filterTables;
    },

    currentFilterStatus() {
      let status = this.statusesList.find(
        s => s.id == this.$route.query.status
      );
      return status ? status.id : null;
    },
  },
};
</script>

<style></style>
